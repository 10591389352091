<template>
  <a
    class="itemscenter flex cursor-pointer items-center gap-1 opacity-50"
    :class="copied ? 'text-[#33FF99]' : ''"
    @click="handleCopy()"
  >
    <Icon :icon="copied ? 'mingcute:check-fill' : 'fa-regular:copy'" />
    <slot />
  </a>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { ref } from 'vue'
import { copyToClipboard } from '~/utils/utils'

const props = defineProps<{
  text: string
}>()

const copied = ref(false)

const handleCopy = () => {
  copyToClipboard(props.text)
  copied.value = true
  setTimeout(() => {
    copied.value = false
  }, 3000)
}
</script>
